@import './colors.css';

.form-input {
  .input {
    min-height: 60px;
    margin-bottom: 5px;

    > label[data-shrink='true'],
    div:focus {
      color: var(--color-grey-text);
      font-size: 25px;
      top: -5px;
    }
    > label .MuiInput-underline:after {
      border-bottom: 2px solid var(--color-grey-);
    }
  }
  .suggestion {
    color: var(--color-grey);

    .suggestion-value {
      color: var(--color-main);
      text-decoration: underline;
    }
  }
}
.MuiInput-underline:after {
  border-bottom-color: var(--color-main);
}
.MuiFormLabel-root.Mui-focused {
  color: var(--color-main);
}

.MuiButton-outlinedPrimary {
  border: 1px solid var(--color-main);
  color: var(--color-main);
}
.MuiPickersToolbar-toolbar {
  background-color: var(--color-main);
}
.MuiPickersDay-daySelected {
  background-color: var(--color-main);
}
.MuiPickersDay-daySelected:hover {
  background-color: var(--color-main);
  opacity: 0.5;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: var(--color-main);
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: var(--color-main);
}
.MuiButton-containedPrimary {
  background-color: var(--color-main);
}
.MuiButton-containedPrimary:hover {
  background-color: var(--color-main);
  opacity: 0.5;
}
.MuiCircularProgress-colorPrimary {
  color: var(--color-main) !important;
}
.MuiButton-textPrimary {
  color: var(--color-main);
}
.MuiCheckbox-colorPrimary.Mui-checked{
  color: var(--color-main);
}
.MuiCheckbox-colorSecondary.Mui-checked,.MuiRadio-colorSecondary.Mui-checked, .MuiSlider-root{
  color: var(--color-main);
}
